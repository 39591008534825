export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "light",
        themes: {
          light: {
            dark: false,
            colors: {
              background: "#e7e7e7", // for background color
              surface: "#FFF", // for card color
              primary: "#A6BBFF",
              rating: "#E0A526",
              button: "#ff7519",
              breadcrumbs: "#023D04",
              menuItem: "#fff",
              cardTitle: "#05840a",
              linkColor: "#068408",
              footerLinkColor: "#94FA96",
              jackpot: "#3a96aa",
              buttonTextColor: "#ffffff",
              buttonBackground: "#3a96aa",
              iconColor: "#E0A526",
              toTop: "#05840a",
              footerLink: "#FFFFE0",
            },
          },
          dark: {},
        },
      },
    });
  });
});
